import React, { useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Can } from "../../utils/Casl_context";
import { UserContext } from "../../context";
import CustomProgress from "../../components/CustomProgress";
import defineRulesFor from "../../utils/Casl_permissions";
import Error500 from "../../views/Error500";
const Routing = ({ routes, ability }) => {
  const { userRole, isAuthenticated, loading } = useContext(UserContext);
  useEffect(() => {
    const rules = defineRulesFor(userRole);
    ability.update(rules);
  }, [ability, userRole]);

  if (loading) {
    return <CustomProgress />;
  } else {
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/auth/login",
          }}
        />
      );
    } else {
      return routes.map((item, index) => {
        return (
          <Can I="go" a={item.path} key={`route-${index}`} passThrough>
            {(allowed) =>
              allowed ? (
                <Route
                  path={item.path}
                  exact={item.exact}
                  render={() => <item.component />}
                />
              ) : userRole !== "" ? (
                <Route path={item.path} exact render={() => <Error500 />} />
              ) : null
            }
          </Can>
        );
      });
    }
  }
};

export default Routing;
