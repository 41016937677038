import * as firebase from "firebase/app";
import "firebase/messaging";
// import {} from "dotenv/config";

const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  apiKey: "AIzaSyCq_neRR13MSb-WZgP9ZNT2LrugECKZ2vQ",
  projectId: "ezcf-beta-push-notification",
  messagingSenderId: "503369425239",
  appId: "1:503369425239:web:9230ae0294d079011bde12"
});
let messaging;

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}
export { messaging };
