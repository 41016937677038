import React, { Suspense, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import io from "socket.io-client";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import Routing from "./Routing";
import { UserContext } from "../../context";
import { Ability } from "@casl/ability";
// import { useToasts } from "react-toast-notifications";
import _get from "lodash/get";
// import _isEmpty from "lodash/isEmpty";
import api from "../../data/utils/api";
import apiEndpoint from "../../data/utils/apiConstants";
import { AbilityContext } from "../../utils/Casl_context";
let socket;
const ability = new Ability();

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    // display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
    "@media (max-width: 780px)": {
      display: "flex",
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    // maxWidth: "97%",
    // marginRight: "1.5rem",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
      width: "50%",
    },
  },
}));
const baseURL =
  process.env.NODE_ENV === "development"
    ? "https://ezcd-stage.entrancezone.com"
    : "/";
function Dashboard({ route }) {
  const classes = useStyles();
  //
  //const { addToast } = useToasts();

  const [onlineUsers, setOnlineUsers] = useState([]);
  const {
    isAuthenticated,
    // notifications,
    setNotifications,
    setNotificationPopup,
  } = useContext(UserContext);
  //const [clonedNotifications, setClonedNotifications] = useState([]);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  // useEffect(() => {
  //   //setClonedNotifications(_cloneDeep(notifications));
  //   const updateNotifications = (data) => {
  //     if (!_isEmpty(data)) {
  //
  //       console.log("dskjvbslidblisdbvslibl");
  //       let temp = notifications;
  //       temp.unshift(data);
  //       setNewNotification({});
  //       setNotifications(temp);
  //     }
  //   };
  //   updateNotifications(newNotification);
  // }, [notifications, newNotification]);

  const addNotifications = async (data) => {
    // const  = clonedNotifications.unshift(data);
    try {
      const res = await api.get(`${apiEndpoint.user}/data`);
      //
      //console.log("addnotifications");

      setNotifications(_get(res, "data.notifications", "[]"));
      setNotificationPopup(undefined);
    } catch (error) {
      //
    }
    //setNewNotification(data);

    // setNotifications();
  };

  // addToast(_get(data, "data.description", "Something went wrong"), {
  //   appearance: "error",
  //   autoDismiss: true,
  // });
  // };
  useEffect(() => {
    if (isAuthenticated) {
      socket = io(baseURL, {
        reconnection: true,
        reconnectionAttempts: 5,
      });
      socket.on("notification", (data) => {
        addNotifications(data);
        setNotificationPopup(data);
        // socket.off();
        //setting state of users online and notifications
      });

      socket.on("online_user", (data) => {
        setOnlineUsers(data);
        // socket.off();
        //setting state of users online and notifications
      });
    }
  }, [isAuthenticated]);
  // useEffect(() => {
  //   // if (markAsRead) {
  //   //   socket.emit("readAll");
  //   // }
  // }, [markAsRead]);
  // console.log(notifications);

  return (
    <>
      <AbilityContext.Provider value={ability}>
        <TopBar
          // ability={ability}
          onlineUsers={onlineUsers}
          //notifications={notifications}
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
        />

        <NavBar
          onMobileClose={() => setOpenNavBarMobile(false)}
          openMobile={openNavBarMobile}
          ability={ability}
        />
        <div className={classes.container}>
          <div className={classes.content}>
            <Suspense fallback={<LinearProgress />}>
              {/*renderRoutes(route.routes)*/}
              <Routing routes={route.routes} ability={ability} />
            </Suspense>
          </div>
        </div>
      </AbilityContext.Provider>
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
