import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors,
} from "@material-ui/core";
import NotificationList from "./NotificationList";
import Placeholder from "./Placeholder";
import _cloneDeep from "lodash/cloneDeep";
import api from "../../data/utils/api";
import { UserContext } from "../../context";
import apiEndpoint from "../../data/utils/apiConstants";
const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: "100%",
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: "center",
  },
}));

function NotificationsPopover({
  allReadCount,
  markAsRead,
  // notifications,
  anchorEl,
  ...rest
}) {
  const classes = useStyles();
  const { getUserAuth, notifications, setNotifications } = useContext(
    UserContext
  );
  // const [tempNotifications, setTempNotifications] = useState([]);
  // useEffect(() => {
  //   setTempNotifications(_cloneDeep(notifications));
  // }, [notifications]);

  const allRead = () => {
    let tempCopy = _cloneDeep(notifications);

    for (const notification of tempCopy) {
      notification.is_read = true;
    }
    setNotifications(tempCopy);
  };

  const maxAttempts = 2;
  let count = 0;
  const markAllReadApi = async () => {
    try {
      if (notifications && notifications.find((item) => !item.is_read)) {
        allReadCount();
        allRead();
        const res = await api.post(`${apiEndpoint.user}/notifications`);
        getUserAuth();
      }
    } catch (error) {
      //error handling
      if (count < maxAttempts) {
        markAllReadApi();
        count++;
      }
    }
  };
  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList
            getUserAuth={getUserAuth}
            //notifications={notifications}
          />
        ) : (
          <Placeholder />
        )}
        <Divider />
        <CardActions className={classes.actions}>
          {notifications.length > 0 ? (
            <Button size="small" onClick={() => markAllReadApi()}>
              Mark All As Read
            </Button>
          ) : null}
        </CardActions>
      </div>
    </Popover>
  );
}

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NotificationsPopover;
