/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
// import DashboardAnalyticsView from "./views/DashboardAnalytics";
import HOF from "./components/HOF/HOF";

export default [
  {
    path: "/",
    exact: true,
    component: HOF(() => <Redirect to="/auth/login" />),
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: HOF(lazy(() => import("src/views/Login"))),
      },
      {
        path: "/auth/register",
        exact: true,
        component: HOF(lazy(() => import("src/views/Register"))),
      },
      {
        path: "/auth/register/generate-password",
        exact: true,
        component: HOF(lazy(() => import("src/views/GeneratePassword"))),
      },
      {
        path: "/auth/reset-password",
        exact: true,
        component: HOF(lazy(() => import("src/views/ResetPassword"))),
      },
      {
        path: "/auth/new-password",
        exact: true,
        component: HOF(lazy(() => import("src/views/NewPassword"))),
      },
      {
        component: HOF(() => <Redirect to="/errors/error-404" />),
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: HOF(lazy(() => import("src/views/Error401"))),
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: HOF(lazy(() => import("src/views/Error404"))),
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: HOF(lazy(() => import("src/views/Error500"))),
      },
      {
        component: HOF(() => <Redirect to="/errors/error-404" />),
      },
    ],
  },
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: "/",
        exact: true,
        component: HOF(() => <Redirect to="/auth/login" />),
      },
      {
        path: "/overview",
        exact: true,
        component: HOF(lazy(() => import("src/views/DashboardAnalytics"))),
      },

      {
        path: "/counsellors",
        exact: true,
        component: HOF(lazy(() => import("src/views/Counsellors"))),
      },
      {
        path: "/leads",
        exact: true,
        component: HOF(lazy(() => import("src/views/Leads"))),
      },
      {
        path: "/quiz-data",
        exact: true,
        component: HOF(lazy(() => import("src/views/Quiz"))),
      },
      {
        path: "/applications",
        exact: true,
        component: HOF(lazy(() => import("src/views/Applications"))),
      },
      {
        path: "/colleges",
        exact: true,
        component: HOF(lazy(() => import("src/views/CollegeList"))),
      },
      {
        path: "/colleges/:id",
        exact: true,
        component: HOF(lazy(() => import("src/views/CollegeProfile"))),
      },

      // {
      //   path: "/overview",
      //   exact: true,
      //   component: HOF(lazy(() => import("src/views/Overview")))
      //   // component: HOF(OverviewView)
      // },

      {
        path: "/counsellors/:id",
        exact: true,
        component: HOF(lazy(() => import("src/views/CounsellorProfile"))),
      },

      {
        path: "/leads/:id",
        exact: true,
        component: HOF(lazy(() => import("src/views/StudentProfile"))),
      },
      {
        path: "/leads/:id/:tab",
        exact: true,
        component: HOF(lazy(() => import("src/views/StudentProfile"))),
      },

      {
        path: "/sources/caf",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/microsite",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/distance",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/ezap",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/apply_now_api",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/quiz",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/abroad",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/college_enquiry",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/sources/newsletter",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage"))),
      },
      {
        path: "/profile/:tab",
        exact: true,
        component: HOF(lazy(() => import("src/views/Profile"))),
      },
      {
        path: "/reminders",
        exact: true,
        component: HOF(
          lazy(() => import("src/views/StudentProfile/Reminders"))
        ),
      },
      {
        path: "/activities",
        exact: true,
        component: HOF(
          lazy(() => import("src/views/StudentProfile/CounsellorActivities"))
        ),
      },
      {
        path: "/panel-users",
        exact: true,
        component: HOF(lazy(() => import("src/views/PanelUsers"))),
      },
      {
        path: "/affiliate/publishers",
        exact: true,
        component: HOF(lazy(() => import("src/views/Publishers"))),
      },
      {
        path: "/affiliate/publishers/:id",
        exact: true,
        component: HOF(lazy(() => import("src/views/PublisherProfile"))),
      },
      {
        path: "/forms",
        exact: true,
        component: HOF(lazy(() => import("src/views/Form"))),
      },
      {
        path: "/forms/:id",
        exact: true,
        component: HOF(lazy(() => import("src/views/Form/FormDetails"))),
      },

      // },
      // {
      //   component: HOF(() => <Redirect to="/errors/error-404" />)
      // }
    ],
  },
];
