/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Snackbar,
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  Menu,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import _get from "lodash/get";

import LockIcon from "@material-ui/icons/LockOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutline";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { AbilityContext } from "../../utils/Casl_context";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsPopover from "src/components/NotificationsPopover";
import PricingModal from "src/components/PricingModal";
import { logout } from "src/actions";
import { UserContext } from "../../context";
import apiEndpoint from "../../data/utils/apiConstants";
import api from "../../data/utils/api";
import ChatBar from "./ChatBar";
import { Can } from "../../utils/Casl_context";
import defineRulesFor from "../../utils/Casl_permissions";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TopBar({
  // notifications,
  // ability,
  onlineUsers,
  onOpenNavBarMobile,
  className,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);

  const notificationsRef = useRef(null);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { setIsAuthenticated, setLoading, notifications } = useContext(
    UserContext
  );
  const [unReadNotifications, setUnReadNotifications] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setUnReadNotifications(notifications.filter((item) => !item.is_read));
  }, [notifications]);

  const allReadCount = () => {
    setUnReadNotifications([]);
  };
  // const apiAxios = axios.create({
  //   withCredentials: true
  // });
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePanelUsers = () => {
    setAnchorEl(null);
    history.push("/panel-users");
  };
  const handleProfileClick = () => {
    setAnchorEl(null);
    history.push("/profile/general");
  };
  const handleLogout = async () => {
    setAnchorEl(null);

    setLoading(true);

    try {
      const res = await api.get(apiEndpoint.logout);
      if (res) {
        setIsAuthenticated(false);
        setLoading(false);
        history.replace("/auth/login");
      }
    } catch (error) {
      setLoading(false);

      setErrorMessage(_get(error, "data.message", "Something went wrong"));
    }
  };

  const handlePricingModalOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingModalClose = () => {
    setPricingModalOpen(false);
  };

  const handleChatBarOpen = () => {
    setOpenChatBar(true);
  };

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };
  const changeCheckBoxStatus = (value) => {
    //handled error
  };
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ backgroundColor: "#3c1a5b" }}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <img alt="Logo" src="/images/logos/ezone.svg" />
          </RouterLink>
        </Hidden>
        <div className={classes.flexGrow} />
        <Can I="view" a="peopleOnline">
          <IconButton
            className={classes.chatButton}
            color="inherit"
            onClick={handleChatBarOpen}
          >
            <Badge badgeContent={onlineUsers.length} className="online-staff">
              <PeopleIcon />
            </Badge>
          </IconButton>
        </Can>
        <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={unReadNotifications.length}
            classes={{ badge: classes.notificationsBadge }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.notificationsButton}
          onClick={handleClick}
        >
          <PersonOutlineOutlinedIcon style={{ color: "white" }} />
        </IconButton>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        // notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
        allReadCount={allReadCount}
      />
      <PricingModal onClose={handlePricingModalClose} open={pricingModalOpen} />
      <ChatBar
        onClose={handleChatBarClose}
        open={openChatBar}
        onlineUsers={onlineUsers}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {ability.can("view", "panel-users") && (
          <MenuItem onClick={handlePanelUsers}>Panel Users</MenuItem>
        )}
        <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "4rem" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
