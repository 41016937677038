import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Page from "src/components/Page";

class MyErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Page
          style={{
            padding: "2rem",
            paddingTop: "10vh",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
          title="Network Error"
        >
          <Typography align="center" variant={"h3"}>
            Connection Time Out.
          </Typography>
          <Typography align="center" variant="subtitle2">
            Something went wrong.
          </Typography>
          <div
            style={{
              marginTop: "4rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              alt="no-network-image"
              style={{
                maxWidth: "20rem",
                maxHeight: 180,
                height: "auto",
              }}
              src="/images/userOffline.png"
            />
          </div>
        </Page>
      );
    }
    return this.props.children;
  }
}

export default MyErrorBoundary;
