export default {
  auth: {
    signIn: "/api/v1/auth/login",
    signUp: "/api/v1/auth/signup",
    generatePassword: "/api/v1/auth/signup/password-create",
    resetPassword: "/api/v1/auth/forgot-password",
    newPassword: "/api/v1/auth/forgot-password/reset",
  },
  user: "api/v1/user",
  logout: "/api/v1/user/logout",
  overview: "api/v1/overview",
  application: "api/v1/application",
  assignedLeads: "/api/v1/lead",
  cafLeads: "/api/v1/source?source=caf",
  micrositeLeads: "microsite",
  ezapLeads: "/api/v1/source?source=ezap",
  distanceLeads: "distance",
  takeAction: "/api/v1/lead",
  addReminder: "/api/v1/lead/reminder",
  counsellorActivity: "/api/v1/counsellor",
  fetchStatusAndActivity: "/api/v1/lead/activity-meta",
  counsellors: "api/v1/counsellor",
  colleges: "api/v1/college",
  leads: "api/v1/lead",
  source: "/api/v1/source",
  panel: "api/v1/panel",
  quiz: "api/v1/quiz",
  publisher: "api/v1/publisher",
  retryPayment: "api/v1/quiz/payment-retry",
  forms: {
    base: "api/v1/form",
    filter: "api/v1/form/filters",
  },
};
