import React, { useState, useEffect, useContext } from "react";
import { Router } from "react-router-dom";
import firebase from "firebase/app";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import { theme, themeWithRtl } from "./theme";
import { configureStore } from "./store";
import routes from "./routes";
// import { UserProvider } from "./context";
import GoogleAnalytics from "./components/GoogleAnalytics";
// import CookiesNotification from "./components/CookiesNotification";
import ScrollReset from "./components/ScrollReset";
import StylesProvider from "./components/StylesProvider";
// import DirectionToggle from "./components/DirectionToggle";
import { messaging } from "./init-fcm";
import api from "./data/utils/api";
import apiEndpoint from "./data/utils/apiConstants";
import { SWRConfig } from "swr";
import { UserContext } from "./context";
import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./mock";
import "./assets/scss/main.scss";

const history = createBrowserHistory();
const store = configureStore();
function App() {
  const [direction, setDirection] = useState("ltr");
  const { isAuthenticated } = useContext(UserContext);
  const pushNotificationTokenSend = async (token) => {
    try {
      if (isAuthenticated) {
        const res = await api.post(`${apiEndpoint.user}/subscribe`, {
          registration_token: token,
        });
      }
    } catch (error) {
      //error handled
    }
  };
  useEffect(() => {
    const permission = async () => {
      messaging
        .requestPermission()
        .then(async function() {
          const token = await messaging.getToken();
          pushNotificationTokenSend(token);
        })
        .catch(function(err) {
          console.log("Unable to get permission to notify.", err);
        });
      navigator.serviceWorker.addEventListener("message", (message) =>
        console.log("notification message", message)
      );
    };
    if (firebase.messaging.isSupported() && isAuthenticated) {
      permission();
    }
  }, [isAuthenticated]);
  // const handleDirecitonToggle = () => {
  //   setDirection((prevDirection) => (prevDirection === "ltr" ? "rtl" : "ltr"));
  // };
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={direction === "rtl" ? themeWithRtl : theme}>
        <StylesProvider direction={direction}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              <SWRConfig
                value={{
                  dedupingInterval: 10000,
                  shouldRetryOnError:false,
                  //refreshInterval: 3000,
                  fetcher: async (url) => {
                    try {
                      const res = await api.get(url);
                      return res;
                    } catch (error) {
                      throw error;
                    }
                  },
                }}
              >
                {renderRoutes(routes)}
              </SWRConfig>
            </Router>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
