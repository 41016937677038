import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  // colors,
  Badge,
  Grid,
  Typography,
} from "@material-ui/core";
import PaymentIcon from "@material-ui/icons/Payment";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import CodeIcon from "@material-ui/icons/Code";
import StoreIcon from "@material-ui/icons/Store";
import gradients from "src/utils/gradients";
import api from "../../data/utils/api";
import apiEndpoint from "../../data/utils/apiConstants";
import { UserContext } from "../../context";
import _cloneDeep from "lodash/cloneDeep";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "18rem",
    overflowY: "scroll",
  },
  listItem: {
    cursor: "pointer",
  },
  notificationsBadge: {
    backgroundColor: "#3c1a5b",
    position: "absolute",
    top: "-3px",
  },
  avatarBlue: {
    backgroundImage: gradients.blue,
  },
  avatarPrimary: {
    backgroundColor: "#3c1a5b",
  },
  avatarGreen: {
    backgroundImage: gradients.green,
  },
  avatarOrange: {
    backgroundImage: gradients.orange,
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo,
  },
  arrowForwardIcon: {
    color: theme.palette.icon,
  },
}));

function NotificationList({ getUserAuth, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { notifications, setNotifications } = useContext(UserContext);

  const avatars = {
    order: (
      <Avatar className={classes.avatarBlue}>
        <PaymentIcon />
      </Avatar>
    ),
    View: (
      <Avatar className={classes.avatarOrange}>
        <PeopleIcon />
      </Avatar>
    ),
    project: (
      <Avatar className={classes.avatarGreen}>
        <StoreIcon />
      </Avatar>
    ),
    feature: (
      <Avatar className={classes.avatarIndigo}>
        <CodeIcon />
      </Avatar>
    ),
  };
  const markSingleNotificationAsRead = async (url, id) => {
    let temp = notifications.find((item) => item.id === id);
    let tempNotifications = _cloneDeep(notifications);
    tempNotifications.forEach((element) => {
      if (element.id === temp.id) {
        element.is_read = true;
      }
    });
    setNotifications(tempNotifications);
    try {
      const temp = new URL(url);
      if (temp.hostname === window.location.host) {
        history.push(temp.pathname);
      } else {
        window.open(url);
      }
      const res = await api.post(`${apiEndpoint.user}/notifications/${id}`);
    } catch (error) {
      //error
    }
  };
  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications.map((notification, i) => (
        <ListItem
          className={classes.listItem}
          divider={i < notifications.length - 1}
          key={notification.id}
          style={!notification.is_read ? { backgroundColor: "#f4f6f8" } : null}
          onClick={() =>
            markSingleNotificationAsRead(
              notification.action_url,
              notification.id
            )
          }
        >
          <ListItemAvatar>{avatars["View"]}</ListItemAvatar>
          <ListItemText
            primary={notification.title}
            primaryTypographyProps={{ variant: "body1" }}
            secondary={notification.description}
          />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-end"
            style={{ width: "35%" }}
          >
            <Grid item>
              {!notification.is_read ? (
                <Badge
                  classes={{ badge: classes.notificationsBadge }}
                  variant="dot"
                />
              ) : null}
            </Grid>
            <Grid item>
              <Typography variant="body2" className="notification-time">
                {moment(notification.created_at).fromNow()}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
}

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array,
};

export default NotificationList;
