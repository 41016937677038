/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, useContext } from "react";
import SchoolIcon from "@material-ui/icons/School";
import FaceIcon from "@material-ui/icons/Face";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import ListIcon from "@material-ui/icons/List";
import { useLocation, matchPath } from "react-router";
import clsx from "clsx";
import getInitials from "src/utils/getInitials";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Can } from "../../utils/Casl_context";
import defineRulesFor from "../../utils/Casl_permissions";
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Link,
  Hidden,
  Badge,
  colors,
} from "@material-ui/core";
import NavItem from "src/components/NavItem";
import { UserContext } from "../../context";
import Label from "src/components/Label";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import LaptopChromebookIcon from "@material-ui/icons/LaptopChromebook";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import ListAltIcon from "@material-ui/icons/ListAlt";
// import navConfig from "./navConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
    color: colors.blueGrey[200],
  },
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  key,
  ...rest
}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <Can I="view" a={item.title} key={item.href}>
        <NavItem
          depth={depth}
          icon={item.icon}
          key={item.href}
          label={item.label}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
          })}
        </NavItem>
      </Can>
    );
  } else {
    acc.push(
      <Can I="view" a={item.title} key={item.href}>
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          label={item.label}
          title={item.title}
        />
      </Can>
    );
  }

  return acc;
}

function NavBar({ ability, openMobile, onMobileClose, className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  const [status, setStatus] = useState("online");
  const { userRole, userName, reminderCount, freshLeadsCount } = useContext(
    UserContext
  );

  const navConfig = [
    {
      subheader: "Pages",
      items: [
        {
          title: "Overview",
          href: "/overview",
          icon: HomeIcon,
        },
        {
          title: "Sources",
          href: "/sources",
          icon: DashboardIcon,
          items: [
            {
              title: "CAF",
              href: "/sources/caf",
            },
            {
              title: "Microsite",
              href: "/sources/microsite",
            },
            {
              title: "Distance",
              href: "/sources/distance",
            },
            {
              title: "EZAP",
              href: "/sources/ezap",
            },
            {
              title: "Newsletter",
              href: "/sources/newsletter",
            },
            {
              title: "Abroad",
              href: "/sources/abroad",
            },
            {
              title: "College Enquiry",
              href: "/sources/college_enquiry",
            },
            {
              title: "Apply Now API",
              href: "/sources/apply_now_api",
            },
            {
              title: "Quiz",
              href: "/sources/quiz",
            },
          ],
        },
        {
          title: "Affiliate Marketing",
          href: "/affiliate",
          icon: GroupWorkIcon,
          items: [
            {
              title: "Publishers",
              href: "/affiliate/publishers",
            },
          ],
        },
        {
          title: "All counsellors",
          href: "/counsellors",
          icon: FaceIcon,
        },
        {
          title: "All Leads",
          href: "/leads",
          icon: PeopleIcon,
          label: () =>
            freshLeadsCount !== 0 ? (
              <Label color={colors.red[500]} shape="rounded">
                {freshLeadsCount}
              </Label>
            ) : null,
        },
        {
          title: "All Forms",
          href: "/forms",
          icon: ListAltIcon,
          // label: () =>
          //   freshLeadsCount !== 0 ? (
          //     <Label color={colors.red[500]} shape="rounded">
          //       {freshLeadsCount}
          //     </Label>
          //   ) : null,
        },
        {
          title: "Quiz Data",
          href: "/quiz-data",
          icon: LaptopChromebookIcon,
        },

        {
          title: "All Applications",
          href: "/applications",
          icon: CheckCircleOutlinedIcon,
        },
        {
          title: "All Colleges",
          href: "/colleges",
          icon: SchoolIcon,
        },
        {
          title: "Reminders",
          href: "/reminders",
          icon: ListIcon,
          label: () =>
            reminderCount !== 0 ? (
              <Label color={colors.red[500]} shape="rounded">
                {reminderCount}
              </Label>
            ) : null,
        },
        {
          title: "Your Activities",
          href: "/activities",
          icon: LocalActivityIcon,
        },
      ],
    },
  ];
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
          })
        )}
      </nav>
      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.onlineBadge]: status === "online",
              [classes.awayBadge]: status === "away",
              [classes.busyBadge]: status === "busy",
              [classes.offlineBadge]: status === "offline",
            }),
          }}
          variant="dot"
        >
          {" "}
          <Avatar className={classes.avatar}>{getInitials(userName)}</Avatar>
        </Badge>
        <div className={classes.details}>
          <Link
            color="inherit"
            variant="h6"
            component={RouterLink}
            to="/profile/general"
          >
            {userName}
          </Link>
          <Typography variant="body2">{userRole}</Typography>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const rules = defineRulesFor(userRole);

    ability.update(rules);
  }, [ability, userRole]);
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
