import React, { createContext, useState, useEffect } from "react";
import apiEndpoint from "./data/utils/apiConstants";
import _get from "lodash/get";
import api from "./data/utils/api";
import _cloneDeep from "lodash/cloneDeep";
export const UserContext = createContext();

export const UserProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [notificationPopup, setNotificationPopup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userName, setUserName] = useState("");
  const [reminderCount, setReminderCount] = useState(0);
  const [freshLeadsCount, setFreshLeadsCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [id, setId] = useState(undefined);
  const [counsellorOptions, setCounsellorOptions] = useState([]);
  const [leadData, setLeadData] = useState({});
  const [quizFilter, setQuizFilter] = useState("");
  const getUserAuth = async () => {
    try {
      const user = await api.get(apiEndpoint.user);
      const userData = await api.get(`${apiEndpoint.user}/data`);
      //const [user, userData] = await Promise.all([resUser, resUserData]);

      setId(_get(user, "data.id", undefined));
      setUserRole(_get(user, "data.user_role", "null"));
      setUserEmail(_get(user, "data.email", "null"));
      setNotifications(_get(userData, "data.notifications", "[]"));
      setReminderCount(_get(userData, "data.reminder_count", 0));
      setFreshLeadsCount(_get(userData, "data.fresh_leads_count", 0));
      setUserName(
        `${_get(user, "data.first_name", "null")} ${_get(
          user,
          "data.last_name",
          "null"
        )} `
      );
      setIsAuthenticated(true);
      setCounsellorOptions(_get(userData, "data.counsellor_options", []));
      setLoading(false);
    } catch (error) {
      setIsAuthenticated(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated === null) {
      getUserAuth();
    }
    //getUserAuth();
  }, []);
  const handleLeadProfile = (data, condition) => {
    switch (condition) {
      case "addActivity": {
        let leadDataCopy = _cloneDeep(leadData);
        const getCounsellorActivity = leadDataCopy.tabs.find(
          (item) => item.key === "counsellor-activity"
        );
        getCounsellorActivity.data.unshift(data);
        const index = leadDataCopy.tabs.findIndex(
          (data) => data.key === "counsellor-activity"
        );
        leadDataCopy.basic.lead_status = data.assigned_status;
        leadDataCopy.tabs[index] = getCounsellorActivity;
        setLeadData(leadDataCopy);
        break;
      }
      case "profileData": {
        setLeadData(data);
        break;
      }
      default: {
      }
    }
  };
  return (
    <UserContext.Provider
      value={{
        id,
        isAuthenticated,
        loading,
        setLoading,
        setIsAuthenticated,
        userEmail,
        userRole,
        notificationPopup,
        setNotificationPopup,
        notifications,
        setNotifications,
        counsellorOptions,
        getUserAuth,
        userName,
        setUserName,
        reminderCount,
        freshLeadsCount,
        filterStatus,
        setFilterStatus,
        handleLeadProfile,
        leadData,
        quizFilter,
        setQuizFilter,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
