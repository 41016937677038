import { AbilityBuilder } from "@casl/ability";

export default function defineRulesFor(role) {
  const { can, cannot, rules } = AbilityBuilder.extract();

  switch (role) {
    case "Admin":
      can("view", "all");
      can("go", "all");
      cannot("view", "Reminders");
      cannot("view", "Your Activities");
      cannot("go", "/reminders");
      cannot("go", "/activities");
      cannot("manage", "reminder");
      cannot("manage", "activity");
      cannot("view", "counsellorOverview");
      can("view", "counsellorTableCell");
      can("view", "peopleOnline");
      // can("view", "assignedCounsellor");
      break;
    case "Counsellor":
      can("view", "All Leads");
      can("view", "All Forms");
      can("view", "All Applications");
      can("view", "Reminders");
      can("view", "Your Activities");
      can("view", "Overview");
      can("view", "counsellorOverview");
      can("view", "general");
      can("view", "security");
      cannot("view", "user-management");
      can("go", "/leads");
      can("go", "/applications");
      can("go", "/");
      can("go", "/profile/:tab");
      can("go", "/errors/error-500");
      can("go", "/overview");
      can("go", "/leads/:id");
      can("go", "/leads/:id/:tab");
      can("go", "/reminders");
      can("go", "/activities");
      can("go", "/forms");
      can("go", "/forms/:id");
      can("manage", "reminder");
      can("manage", "activity");
      break;

    default:
      cannot("view", "all");
  }

  return rules;
}
