/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Suspense,
} from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import CustomProgress from "../CustomProgress";
import { UserContext } from "../../context";
import { Route, Redirect } from "react-router-dom";
import MyErrorBoundary from "./MyErrorBoundary";
import _get from "lodash/get";
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const App = (props) => {
  const {
    isAuthenticated,
    loading,
    // data,
    // getUserAuth,
    notificationPopup,
  } = useContext(UserContext);

  const { Component, ...rest } = props;
  const { addToast, removeAllToasts } = useToasts();
  const [isOnline, setIsOnline] = useState(
    window ? window.navigator.onLine : false
  );

  window.addEventListener("online", () => {
    setIsOnline(true);
  });
  window.addEventListener("offline", () => {
    setIsOnline(false);
  });

  document.addEventListener("copy", (e) => {
    const pagelink = `\n\nRead more at: ${document.location.href}`;
    e.clipboardData.setData("text", document.getSelection() + pagelink);
    e.preventDefault();
  });
  const content = (
    <div>
      <strong className="notification-title">
        {isOnline ? "Online" : "Offline"}
      </strong>
      <div className="notification-description">
        {isOnline ? "You're online now" : "You're not connected to any network"}
      </div>
    </div>
  );
  const prevIsOnline = usePrevious(isOnline);
  useEffect(() => {
    if (!isOnline) {
      addToast(content, {
        appearance: "info",
        autoDismiss: false,
      });
    } else if (prevIsOnline === false && isOnline) {
      removeAllToasts();
      addToast(content, {
        appearance: "info",
        autoDismiss: true,
      });
    }
  }, [isOnline, addToast]);
  // useEffect(() => {
  //   //make api only if the authenticated value is null. Else come to the conclusion.
  //   if (isAuthenticated === undefined) {
  //     getUserAuth();
  //   }
  // }, []);
  useEffect(() => {
    if (notificationPopup) {
      addToast(notificationPopup.description, {
        appearance: "success",
        autoDismiss: true,
      });
    }
  }, [notificationPopup]);
  if (loading) {
    return <CustomProgress />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (
            props.location.pathname === "/auth/login" ||
            props.location.pathname === "/auth/register/generate-password" ||
            props.location.pathname === "/auth/register" ||
            props.location.pathname === "/auth/reset-password"
          ) {
            return isAuthenticated ? (
              <Redirect
                to={{
                  pathname: "/overview",
                  state: { from: props.location },
                }}
              />
            ) : (
              <Component {...props} />
            );
          } else if (props.location.pathname === "/auth/new-password") {
            return <Component {...props} />;
          } else {
            return isAuthenticated ? (
              <MyErrorBoundary>
                <Suspense fallback={<CustomProgress />}>
                  <Component {...props} />
                </Suspense>
              </MyErrorBoundary>
            ) : (
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: props.location },
                }}
              />
            );
          }
        }}
      />
    );
  }

  //return (isAuthenticated?<Component {...props} />:);
};

const HOF = (Component) =>
  function ReturnHOF(props) {
    return (
      <div>
        <ToastProvider
          style={{ zIndex: "100000000000000 !important" }}
          placement={"top-right"}
          autoDismissTimeout={6000}
        >
          <App Component={Component} {...props}></App>
        </ToastProvider>
      </div>
    );
  };
export default HOF;
